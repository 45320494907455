<template>
    <div class="instalments-table">
        <b-table
            id="createInstalmentsTable"
            ref="createInstalmentsTable"
            :key="isBusy"
            class="shadow"
            :items="instalments2"
            :fields="fields"
            :busy.sync="isBusy"
            hover
            show-empty
            @row-clicked="onRowClicked"
            >
            <template #cell(date)="row">
              <div :class="{red: !isValidDate(row.index)}">
                {{ toFormattedDate(row.item.date) }}
              </div>
            </template>
            <template #cell(pre)="row">
              <div :class="{red: !isValidType(row.index)}">
                {{ (row.item.pre) ? 'P' : 'A' }}
              </div>
            </template>
            <template #cell(isPaid)="row">
                <div class="m-auto" :class="{paid: row.item.isPaid}">
                    {{ row.item.isPaid ? 'Pagata' : ''}}
                </div>
            </template>
            <template #head(amount)>
                Importo rata
                <div>
                  <b-form-checkbox v-model="autofill">
                      Completa automaticamente
                  </b-form-checkbox>
                </div>
            </template>
            <template #cell(amount)="row">
              <!-- to do adjust: focus does not work properly, improve keys for reactivity -->
              <CurrencyInput
                :ref="`input_${row.index}`"
                :key="`input_${row.index}_${selectedRow}_${autofill}`"
                :class="{invalid: showErrors}"
                id="input-amount"
                v-model="row.item.amount"
                @focus="selectedRow = row.index;"
                @keydown.enter="focusOnInput(row.index+1)"
                @blur="showErrorAmount = true; selectedRow = null;"
                @change="autofillAmounts(row.index, row.item.amount); $emit('backup')"
              />
            </template>
            <template #cell(actions)="row">
                <b-dropdown
                :ref="`dropdown_${row.index}`"
                class="text-right"
                size="sm"
                id="dropdown-1"
                right
                variant="link"
                toggle-class="text-decoration-none"
                >
                <template #button-content>
                    <i class="fas fa-ellipsis-v px-2" tabindex="0"></i>
                </template>
                <b-dropdown-item
                    @click="$emit('openEditInstalmentPopup', row.item)"
                    tabindex="0"
                    >Modifica</b-dropdown-item
                >
                <b-dropdown-item
                    @click="$emit('openDeleteInstalmentPopup', row.item)"
                    tabindex="0"
                    class="red"
                    >Elimina</b-dropdown-item
                >
                </b-dropdown>
            </template>
            <template #empty>
                <div class="d-flex justify-content-center align-items-center py-2">
                    Nessuna rata trovata
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>

import { BDropdown, BDropdownItem, BTable } from 'bootstrap-vue';
import { toFormattedDate } from '@/helpers/dates.js';
import { isFilled } from '@/helpers/formValidation.js';
// import CellContent from '@/views/components/Table/CellContent.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

export default {
  props: {
    instalments: Array,
    loadingInstalments: Boolean,
    showErrors: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    // CellContent,
    CurrencyInput,
  },
  watch: {
    autofill () {
      if (this.autofill) {
        this.autofillAmounts();
      }
    },
    validDates () {
      this.$emit('validDates', this.validDates);
    },
    validTypes () {
      this.$emit('validTypes', this.validTypes);
    },
    validAmounts () {
      this.$emit('validAmounts', this.validAmounts);
    },
  },
  data () {
    return {
      fields: [
        { key: 'nRata', label: 'Rata', sortable: false },
        { key: 'pre', label: 'Tipo rata', sortable: false },
        { key: 'date', label: 'Scadenza', sortable: false },
        { key: 'amount', label: 'Importo rata', sortable: false, class: 'amount' },
        { key: 'isPaid', label: '', sortable: false, class: 'paid-column' },
        { key: 'actions', label: '', sortable: false },
      ],
      selectedRow: null,
      autofill: false,
    };
  },
  computed: {
    isBusy () {
      return this.loadingInstalments;
    },
    instalments2 () {
      return this.instalments ? this.instalments : [];
    },
    validDates () {
      return !this.instalments2.find((obj, i) => (!this.isValidDate(i)));
    },
    validTypes () {
      return !this.instalments2.find((obj, i) => (!this.isValidType(i)));
    },
    validAmounts () {
      return !this.instalments2.find((obj, i) => (!this.isValidAmount(i)));
    },
  },
  methods: {
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    focusOnInput (i) {
      /*
      // to do improve (quando arriva alla fine unfocus)
      if (this.instalments2[i]?.amount) {
        return this.focusOnInput(i + 1);
      } else {
        this.$nextTick(() => {
          try {
            this.$refs[`input_${i}`].focus();
          } catch (e) {
            // to do focus on main button
            // this.$log.error(e);
          }
        });
      }
      */
    },
    onRowClicked (e, i) {
      this.selectedRow = i;
      // this.$nextTick(() => this.$refs[`input_${i}`].focus());
    },
    autofillAmounts (startIndex = 0, amount = null) {
      if (this.autofill) {
        if (amount) {
          for (let i = startIndex; i < this.instalments2.length; i++) {
            this.instalments2[i].amount = amount;
          }
        } else {
          let value = null;
          for (let i = startIndex; i < this.instalments2.length; i++) {
            if (this.instalments2[i].amount) {
              value = this.instalments2[i].amount;
            } else {
              this.instalments2[i].amount = value;
            }
          }
        }
        this.$emit('backup');
      }
    },
    isValidDate (i) {
      const newDate = new Date(this.instalments2[i]?.date);
      const prevDate = new Date(this.instalments2[i - 1]?.date);

      return (this.instalments2.length <= 1 || i === 0) || prevDate < newDate;
    },
    isValidType (i) {
      if (i > 0 && this.instalments2[i - 1].pre === false && this.instalments2[i].pre === true) {
        return false;
      }
      return true;
    },
    isValidAmount (i) {
      return isFilled(this.instalments2[i].amount);
    },
  },
  mounted () {
    this.$emit('validDates', this.validDates);
    this.$emit('validTypes', this.validTypes);
    this.$emit('validAmounts', this.validAmounts);
  },
};
</script>

<style lang="scss">
.instalments-table {
    .table {
        border-radius: 10px;

        td:nth-of-type(1), th:nth-of-type(1),
        td:nth-of-type(2), th:nth-of-type(2) {
            width: 10%;
        }
        td:nth-of-type(3), th:nth-of-type(3) {
            max-width: 15%;
        }
        td:nth-last-of-type(1) {
            width: 50px;
        }
        .amount {
            text-align: right;
            width: auto;
        }
        .paid-column {
            width: 200px;
        }
    }
    .paid {
        justify-content: center;
        background: $primary;
        opacity: 0.75;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        padding: 1px 7px;
        margin: 0;
        gap: 10px;
        border-radius: 5px;
        width: fit-content;
    }
    .custom-control.custom-checkbox {
        .custom-control-label {
            color: $primary;
            padding-top: 3px;
            font-size: 12px;
            text-transform: lowercase;
            justify-items: center;
            &::first-letter {
              text-transform: capitalize;
            }
        }
    }
}
</style>
