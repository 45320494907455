<template>
    <td :class="{red: isNegative}" class="selectable">
        {{ valueWithDecimals }}
    </td>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    valueWithDecimals () {
      return this.value ? this.value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : this.value;
    },
    isNegative () {
      return this.value < 0;
    },
  },
};
</script>
