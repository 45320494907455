<template>
<div class="edit-instalments">
    <form
      ref="editInstalment"
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <h2 class="mb-4">Modifica rata n. {{ instalment.nRata }}</h2>
        <div class="row">
            <div class="col-4">
                <b-form-group
                    class="m-0"
                    id="input-group-type"
                    label="Tipo rata"
                    label-for="input-type"
                    :class="{ invalid: !isValidType && showErrorType }"
                >
                    <b-form-select
                    id="input-type"
                    v-model="pre"
                    @blur="showErrorType = true"
                    >
                    <option :value="true">P</option>
                    <option :value="false">A</option>
                    </b-form-select>
                    <b-form-invalid-feedback :state="false" :class="{ invisible: isValidType || !showErrorType }" >
                    <i class="fas fa-exclamation-circle"></i> Seleziona un tipo
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="input-group-expiration"
                    label="Scadenza"
                    label-for="input-expiration"
                    :class="{ invalid: !isValidExpiration && showErrorExpiration }"
                >
                    <b-form-input
                    id="input-expiration"
                    v-model="expiration"
                    type="date"
                    @blur="showErrorExpiration = true"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="false" :class="{ invisible: isValidExpiration || !showErrorExpiration }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci una scadenza valida
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <b-form-group
                    id="input-group-amount"
                    label="Importo"
                    label-for="input-amount"
                    :class="{ invalid: !isValidAmount && showErrorAmount }"
                >
                    <b-form-input
                    id="input-amount"
                    v-model="amount"
                    type="text"
                    placeholder=""
                    @blur="showErrorAmount = true"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </div>

        <div class="row mb-4 justify-content-start">
            <div class="col">
                <b-form-checkbox v-model="isPaid" name="input-paid">
                    Rata pagata
                </b-form-checkbox>
            </div>
        </div>

      <custom-button
          :isLoading="loading"
          label="Salva"
          class="w-100"
          @click.prevent.native="onSubmit"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
</div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isValidYear } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    companyId: String,
    instalment: Object,
  },
  components: {
    ErrorCard,
    'custom-button': Button,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorType: false,
      showErrorExpiration: false,
      showErrorAmount: false,

      loading: false,
      errorMessage: '',

      // Form
      pre: this.instalment?.pre || null,
      expiration: this.instalment?.date || null,
      amount: parseFloat(this.instalment?.amount).toFixed(2) || null,
      isPaid: this.instalment?.isPaid || false,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidType && this.isValidExpiration && this.isValidAmount;
    },
    isValidType () {
      return isFilled(this.pre);
    },
    isValidExpiration () {
      return isDate(this.expiration) && isValidYear(this.expiration, 2000, 2200);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
  },
  methods: {
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        const obj = Object.assign(JSON.parse(JSON.stringify(this.instalment)), { pre: this.pre, date: this.expiration, amount: this.amount, isPaid: this.isPaid });
        this.$emit('save', obj);
        /*
        this.$api.updateLoan(this.companyId, this.counterpart, parseFloat(this.amount), this.expiration, this.description, 'accredito', this.bank.id)
          .then((res) => {
            // this.$log.debug(res);
            this.$root.$emit('updateLoans');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = 'Qualcosa è andato storto. Controlla i dati inseriti';
          })
          .finally(() => {
            this.loading = false;
          });
        */
      } else {
        this.loading = true;
        if (!this.isValidType) {
          this.showErrorType = true;
        } else if (!this.isValidExpiration) {
          this.showErrorExpiration = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    updateVariables () {
      this.pre = this.instalment.pre;
      this.expiration = this.instalment.date;
      this.amount = this.instalment.amount ? parseFloat(this.instalment.amount).toFixed(2) : null;
      this.isPaid = this.instalment.isPaid;
    },
  },
  mounted () {
    if (this.instalment) {
      this.updateVariables();
    }
  },
};
</script>

<style lang="scss">
</style>
