<template>
  <div class="loan">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <div class="row">
        <div class="col-6">
            <b-form-group
                class="mb-3 box px-0"
                id="input-group-name"
                label="Nome"
                label-for="input-name"
                :class="{ invalid: !isValidName && showErrorName }"
            >
                <b-form-input
                id="input-name"
                v-model="name"
                type="text"
                @blur="showErrorName = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="col-6">
          <BankSelect ref="bankSelect" key="bankSelectLeasing" :banks="banks" :selectedItem="bank" @search:blur="showErrorBank = true" @update="updateBank" :class="{ invalid: !isValidBank && showErrorBank }"/>
          <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
            <i class="fas fa-exclamation-circle"></i> Inserisci una banca
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="d-flex mb-3">
        <b-form-group>
            <b-form-radio-group
                id="radio-group-2"
                v-model="granted"
            >
                <b-form-radio :value="false">Da erogare</b-form-radio>
                <b-form-radio :value="true">Erogato</b-form-radio>
            </b-form-radio-group>
        </b-form-group>
      </div>

      <div class="d-flex row">
        <div class="col-6">
          <b-form-group
            :key="`isgranted_${granted}`"
            class="mb-3"
            id="input-group-amount"
            :label="granted ? 'Debito residuo' : 'Importo del finanziamento'"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount }"
          >
            <CurrencyInput id="input-amount" v-model="amount" @blur="showErrorAmount = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-release"
            :label="granted ? 'Data pagamento ultima rata' : 'Data erogazione'"
            label-for="input-release"
            :class="{ invalid: !isValidDate && showErrorDate }"
          >
            <b-form-input
              id="input-release"
              v-model="releaseDate"
              type="date"
              @blur="showErrorDate = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDate || !showErrorDate }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una data valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-instalment-frequency"
            label="Frequenza delle rate"
            label-for="input-instalment-frequency"
            :class="{ invalid: !isValidInstalmentFrequency && showErrorInstalmentFrequency }"
          >
            <b-form-select
              id="input-instalment-frequency"
              v-model="instalmentFrequency"
              type="number"
              @blur="showErrorInstalmentFrequency = true"
            >
              <option :value="1">Mensile</option>
              <option :value="3">Trimestrale</option>
              <option :value="6">Semestrale</option>
              <option :value="12">Annuale</option>
            </b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidInstalmentFrequency || !showErrorInstalmentFrequency }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci il numero di rate
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-rate"
            label="Tasso (%)"
            label-for="input-rate"
            :class="{ invalid: !isValidRate && showErrorRate }"
          >
            <b-form-input
              id="input-rate"
              v-model="rate"
              type="number"
              @blur="showErrorRate = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidRate || !showErrorRate }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un tasso in percentuale valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-iva"
            label="IVA su quota capitale (%)"
            label-for="input-iva"
            :class="{ invalid: !isValidIva && showErrorIva }"
          >
            <b-form-input
              id="input-iva"
              v-model="iva"
              type="number"
              @blur="showErrorIva = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidIva || !showErrorIva }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci l'IVA in percentuale
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-4 justify-content-start">
        <div class="col-4">
          <b-form-checkbox v-model="lastOfMonth" name="input-last-of-month">
            Fine mese
          </b-form-checkbox>
        </div>
      </div>

      <div class="row mb-4">
        <!-- ammortamento -->
        <div class="col-12">
          <div class="simple-card">
            <div class="title w-100">
              Ammortamento
            </div>
            <div class="row content">
              <div class="col-5">
                <b-form-group
                  class="mb-0 mt-2"
                  id="input-group-amortization-instalments"
                  :label="granted ? 'Rate residue' : 'Numero rate'"
                  label-for="input-amortization-instalments"
                  :class="{ invalid: !isValidAmortizationInstalments && showErrorAmortizationInstalments }"
                >
                  <b-form-input
                    id="input-amortization-instalments"
                    v-model="amortization_instalments"
                    type="number"
                    @blur="showErrorAmortizationInstalments = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmortizationInstalments || !showErrorAmortizationInstalments }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un numero valido
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-7">
                <b-form-group
                  class="mb-0 mt-2"
                  id="input-group-amortization-expiration"
                  :label="granted ? 'Data prossima scadenza' : 'Data prima scadenza'"
                  label-for="input-amortization-expiration"
                  :class="{ invalid: !isValidAmortizationExpiration && showErrorAmortizationExpiration }"
                >
                  <b-form-input
                    id="input-amortization-expiration"
                    v-model="amortization_expiration"
                    type="date"
                    @blur="showErrorAmortizationExpiration = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmortizationExpiration || !showErrorAmortizationExpiration }" >
                    <i class="fas fa-exclamation-circle"></i> {{ lastOfMonth && amortization_expiration ? "Il giorno selezionato non è l'ultimo del mese." : 'Inserisci una data valida'}}
                    <button v-if="lastOfMonth && amortization_expiration " class="d-inline font-weight-bolder no-btn p-0" @click.prevent="setLastDayOfMonth()">Clicca qui per reimpostare</button>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <!-- 1 -->
        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-advance"
            label="Importo anticipo"
            label-for="input-advance"
            :class="{ invalid: !isValidAdvance && showErrorAdvance }"
          >
            <CurrencyInput id="input-advance" v-model="advance" @blur="showErrorAdvance = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAdvance || !showErrorAdvance }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <!-- 2 -->
        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-refund"
            label="Importo riscatto"
            label-for="input-refund"
            :class="{ invalid: !isValidRefund && showErrorRefund }"
          >
            <CurrencyInput id="input-refund" v-model="refund" @blur="showErrorRefund = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidRefund || !showErrorRefund }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <custom-button
          :isLoading="loading"
          :label="loan ? 'Salva' : 'Aggiungi'"
          class="w-100"
          @click.prevent.native="onSubmit"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
  </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BFormRadioGroup, BFormRadio, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isAlphanumeric, isCurrency, isDate, isValidYear, isNumber } from '@/helpers/formValidation.js';
import { toStandardDate } from '@/helpers/dates.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    companyId: String,
    loan: Object,
    setup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BankSelect,
    'custom-button': Button,
    ErrorCard,
    CurrencyInput,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
  },
  watch: {
    loan () {
      this.updateData();
    },
    lastOfMonth () {
      if (this.lastOfMonth) {
        this.setLastDayOfMonth();
        this.showErrorAmortizationExpiration = true;
      }
    },
    amortization_expiration () {
      if (this.lastOfMonth && this.amortization_expiration) {
        this.$log.debug(this.lastOfMonth);
        // this.setLastDayOfMonth();
        this.showErrorAmortizationExpiration = true;
      }
    },
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorBank: false,
      showErrorName: false,
      showErrorAmount: false,
      showErrorDate: false,
      showErrorInstalmentFrequency: false,
      showErrorRate: false,
      showErrorIva: false,
      showErrorAmortizationInstalments: false,
      showErrorAmortizationExpiration: false,
      showErrorAdvance: false,
      showErrorRefund: false,

      loading: false,
      errorMessage: '',

      // Form
      bank: null,
      name: '',
      granted: false,
      amount: null,
      releaseDate: null,
      instalmentFrequency: null,
      rate: null,
      iva: '',
      lastOfMonth: false,
      amortization_instalments: null,
      amortization_expiration: null,
      advance: null,
      refund: null,
    };
  },
  computed: {
    formattedBanks () {
      return this.banks.map(obj => obj.name);
    },
    bankInfo () {
      return this.banks.find(obj => obj.name === this.bank);
    },
    isValidForm () {
      return this.isValidBank && this.isValidName && this.isValidAmount && this.isValidDate && this.isValidInstalmentFrequency && this.isValidIva && this.isValidRate && this.isValidAmortizationInstalments && this.isValidAmortizationExpiration && this.isValidAdvance && this.isValidRefund;
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidName () {
      return isFilled(this.name) && isAlphanumeric(this.name);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidDate () {
      return isDate(this.releaseDate) && isValidYear(this.releaseDate, 2000, 2100);
    },
    isValidInstalmentFrequency () {
      return isFilled(this.instalmentFrequency);
    },
    isValidIva () {
      return isFilled(this.iva) && isNumber(this.iva) && this.iva >= 0;
    },
    isValidRate () {
      return isFilled(this.rate);
    },
    isValidAmortizationInstalments () {
      return isFilled(this.amortization_instalments) && isNumber(this.amortization_instalments) && this.amortization_instalments > 0;
    },
    isValidAmortizationExpiration () {
      return isDate(this.amortization_expiration) && this.isValidLastDay(this.amortization_expiration);
    },
    isValidAdvance () {
      return !isFilled(this.advance) || isCurrency(this.advance);
    },
    isValidRefund () {
      return !isFilled(this.refund) || isCurrency(this.refund);
    },
  },
  methods: {
    getBankById (bankId) {
      return this.banks.find(bank => bank.id === bankId);
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        if (this.setup) {
          const piano = Object.assign({}, {
            frequenzaRate: this.instalmentFrequency,
            tasso: this.rate,
            tipoPianoId: 3,
            fineMese: this.lastOfMonth,
            nbRate: this.amortization_instalments,
            primaScadenza: this.amortization_expiration,
            nbRatePreammortamento: null,
            primaScadenzaPreammortamento: null,
            iva: this.iva,
            maxiRataIniziale: this.advance, // anticipo
            maxiRataFinale: this.refund, // riscatto
            rate: null,
          });

          const loan = Object.assign({}, {
            bankId: this.bank.id,
            label: this.name,
            isPaid: this.granted,
            isCalculated: true,
            importo: this.amount,
            paidDate: this.releaseDate,
            piano: piano,
          });

          return this.$emit('save', loan);
        }

        this.loading = true;
        this.$api.createLoan(
          this.companyId,
          this.name,
          this.bank.id, // to do check id
          this.granted, // isPaid
          true, // isCalculated
          this.amount, // to do check if float required
          this.releaseDate, // paidDate
          this.instalmentFrequency, // frequenzaRate
          this.rate, // tasso
          3, // tipoPianoId
          this.amortization_instalments, // nbRate (ammortamento),
          this.amortization_expiration, // primaScadenza (ammortamento),
          null, // nbRatePreammortamento
          null, // primaScadenzaPreammortamento,
          this.lastOfMonth,
          this.iva, // iva
          this.advance, // maxiRataIniziale o anticipo
          this.refund, // maxiRataFinale o riscatto
          [], // rate (manuale)
          this.loan ? this.loan.id : null, // id
        )
          .then((res) => {
            this.$log.debug(res);
            const loanId = res.data.id;
            if (loanId) {
              this.$router.replace({ name: 'loanDetails', params: { id: loanId } });
            }
            this.$emit('save');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = 'Qualcosa è andato storto. Controlla i dati inseriti';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidDate) {
          this.showErrorDate = true;
        } else if (!this.isValidInstalmentFrequency) {
          this.showErrorInstalmentFrequency = true;
        } else if (!this.isValidRate) {
          this.showErrorRate = true;
        } else if (!this.isValidIva) {
          this.showErrorIva = true;
        } else if (!this.isValidAmortizationInstalments) {
          this.showErrorAmortizationInstalments = true;
        } else if (!this.isValidAmortizationExpiration) {
          this.showErrorAmortizationExpiration = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    updateData () {
      this.bank = this.getBankById(this.loan.bankId);
      this.name = this.loan.label;
      this.amount = this.loan.importo;
      this.releaseDate = this.loan.paidDate;
      this.granted = Boolean(this.loan.paidDate);
      this.instalmentFrequency = this.loan.piano.frequenzaRate;
      this.plan = this.loan.piano.tipoPianoId;
      this.rate = this.loan.piano.tasso;
      this.lastOfMonth = this.loan.piano.fineMese;
      this.iva = this.loan.piano.iva;
      this.amortization_instalments = this.loan.piano.nbRate;
      this.amortization_expiration = this.loan.piano.primaScadenza;
      this.advance = this.loan.piano.maxiRataIniziale;
      this.refund = this.loan.piano.maxiRataFinale;
    },
    updateBank (bank) {
      this.bank = bank;
    },
    setLastDayOfMonth () {
      if (this.amortization_expiration) {
        const date = new Date(this.amortization_expiration);
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.amortization_expiration = toStandardDate(newDate);
        return ('0' + newDate.getDate()).slice(-2);
      }
    },
    toStandardDate (s) {
      return toStandardDate(s);
    },
    isValidLastDay (date) {
      if (this.lastOfMonth) {
        const newDate = new Date(date);
        const date2 = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);

        this.$log.debug('is last day ' + newDate.getMonth() !== date2.getMonth());
        return newDate.getMonth() !== date2.getMonth();
      }
      return true;
    },
  },
  mounted () {
    if (this.loan) {
      this.updateData();
    }
  },
};
</script>

<style lang="scss">
.simple-card {
  background: $fin-white;
  border-radius: 10px;
  overflow: hidden;
  .title {
    background: $primary-lighter;
    color: $primary;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 10px;
  }
  .content {
    padding: 5px 15px;
  }
  label.custom-control-label {
    font-weight: 400;
  }
}
</style>
