export default function (value, useRaw = false) {
  if (useRaw) {
    return value;
  }

  if (value === 'scadenzario_passivo') {
    return 'scadenzario passivo';
  } else if (value === 'stipendi' || value === 'stipendio') {
    return 'stipendio';
  } else if (value === 'altri_bonifici' || value === 'bonifico_uscita') {
    return 'altro bonifico addebitato';
  } else if (value === 'girofondi') {
    return 'girofondo';
  } else if (value === 'girofondo_entrata') {
    return 'accredito girofondo';
  } else if (value === 'girofondo_uscita') {
    return 'addebito girofondo';
  } else if (value === 'riba_titoli') {
    return 'riba titolo';
  } else if (value === 'riba_attiva') {
    return 'Ri.Ba. o titolo di credito attivo';
  } else if (value === 'riba_passiva') {
    return 'Ri.Ba o titolo di credito passivo';
  } else if (value === 'finanziamenti') {
    return 'finanziamento';
  } else if (value === 'finanziamento_entrata') {
    return 'accredito finanziamento';
  } else if (value === 'finanziamento_uscita') {
    return 'rata finanziamento';
  } else if (value === 'anticipi') {
    return 'anticipo';
  } else if (value === 'anticipo_entrata') {
    return 'accredito anticipo';
  } else if (value === 'anticipo_uscita') {
    return 'estinzione anticipo';
  } else if (value === 'pagamento_fiscale') {
    return 'pagamento fiscale';
  } else if (value === 'versamenti_prelievi') {
    return 'versamento/prelievo';
  } else if (value === 'competenze_oneri') {
    return 'competenze oneri';
  } else if (value === 'addebiti_accrediti') {
    return 'addebito/accredito';
  } else if (value === 'scadenzario_passivo') {
    return 'scadenzario passivo';
  } else if (value === 'scadenzario_attivo') {
    return 'scadenzario attivo';
  } else if (value === 'accredito') {
    return 'altro accredito';
  } else if (value === 'competenza') {
    return 'competenza o altro onere bancario';
  } else if (value === 'addebito') {
    return 'altro addebito';
  }

  return value;
};
